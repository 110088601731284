/**
 * --------------------------------------------------------------------------
 * ASKUL SAG
 * Copyright
 * --------------------------------------------------------------------------
 */

/*
   シンクロエージェント共通処理
*/

$(() => {
  console.log('SAG loaded')

  const jsonData = [
    {
      branch: '',
      qid: 1,
      clientName: '株式会社明日来商事',
      personName: '明日来小次郎',
      tel: '03-0000-0000',
      mail: '照会',
      deadline: '10日締め',
      pay: 'AG郵振CSV',
      ccreditLimit: '&yen; 30,000',
      free: 'ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト',
      possible: '-',
      stopReason: '-',
      category: '納品書',
      status: '照会'
    },
    {
      branch: '',
      qid: 2,
      clientName: 'あすくる図書館',
      personName: '水木一郎',
      tel: '03-1000-1000',
      mail: '照会',
      deadline: '月末日締め',
      pay: 'AG口座振替',
      ccreditLimit: '&yen; 40,000',
      free: '-',
      possible: '-',
      stopReason: '-',
      category: '領収書',
      status: '照会'
    },
    {
      branch: '',
      qid: 3,
      clientName: 'あいうえ',
      personName: 'なにぬねのはひふへほ',
      tel: '03-9999-9999',
      mail: '照会',
      deadline: '月末日締め',
      pay: 'AG口座振替',
      ccreditLimit: '&yen; 200,000',
      free: 'ダミーテキストダミーテキストダミーテキスト',
      possible: '-',
      stopReason: '-',
      category: '領収書',
      status: '照会'
    },
    {
      branch: '',
      qid: 4,
      clientName: 'あいうえ',
      personName: 'なにぬねのはひふへほ',
      tel: '03-9999-9999',
      mail: '照会',
      deadline: '月末日締め',
      pay: 'AG口座振替',
      ccreditLimit: '&yen; 200,000',
      free: '-',
      possible: '-',
      stopReason: '-',
      category: '領収書',
      status: '照会'
    },
    {
      branch: '',
      qid: 5,
      clientName: 'あいうえ',
      personName: 'なにぬねのはひふへほ',
      tel: '03-9999-9999',
      mail: '照会',
      deadline: '月末日締め',
      pay: 'AG口座振替',
      ccreditLimit: '&yen; 200,000',
      free: 'ダミーテキスト',
      possible: '-',
      stopReason: '-',
      category: '領収書',
      status: '照会'
    },
    {
      branch: '',
      qid: 6,
      clientName: 'あいうえ',
      personName: 'なにぬねのはひふへほ',
      tel: '03-9999-9999',
      mail: '照会',
      deadline: '月末日締め',
      pay: 'AG口座振替',
      ccreditLimit: '&yen; 200,000',
      free: '-',
      possible: '-',
      stopReason: '-',
      category: '領収書',
      status: '照会'
    },
    {
      branch: '',
      qid: 7,
      clientName: 'あいうえ',
      personName: 'なにぬねのはひふへほ',
      tel: '03-9999-9999',
      mail: '照会',
      deadline: '月末日締め',
      pay: 'AG口座振替',
      ccreditLimit: '&yen; 200,000',
      free: 'ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト',
      possible: '-',
      stopReason: '-',
      category: '領収書',
      status: '照会'
    },
    {
      branch: '',
      qid: 8,
      clientName: 'あいうえ',
      personName: 'なにぬねのはひふへほ',
      tel: '03-9999-9999',
      mail: '照会',
      deadline: '月末日締め',
      pay: 'AG口座振替',
      ccreditLimit: '&yen; 200,000',
      free: 'ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト',
      possible: '-',
      stopReason: '-',
      category: '領収書',
      status: '照会'
    },
    {
      branch: '',
      qid: 9,
      clientName: 'あいうえ',
      personName: 'なにぬねのはひふへほ',
      tel: '03-9999-9999',
      mail: '照会',
      deadline: '月末日締め',
      pay: 'AG口座振替',
      ccreditLimit: '&yen; 200,000',
      free: 'ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト',
      possible: '-',
      stopReason: '-',
      category: '領収書',
      status: '照会'
    },
    {
      branch: '',
      qid: 10,
      clientName: 'あいうえ',
      personName: 'なにぬねのはひふへほ',
      tel: '03-9999-9999',
      mail: '照会',
      deadline: '月末日締め',
      pay: 'AG口座振替',
      ccreditLimit: '&yen; 200,000',
      free: 'ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト',
      possible: '-',
      stopReason: '-',
      category: '領収書',
      status: '照会'
    }

  ]

  console.log('init datatable')

  const settings = {
    data: jsonData,
    pageLength: 5,
    lengthMenu: [[1, 5, 10, -1], [1, 5, 10, 'All']],
    dtFixedHeader: false,
    dtHead: [
      {
        data: 'branch',
        label: '枝番'
      },
      {
        data: 'qid',
        label: 'お問い合わせ番号'
      },
      { data: 'clientName',
        label: 'お客様名'
      },
      {
        data: 'personName',
        label: '担当者名'
      },
      {
        data: 'tel',
        label: '電話番号'
      },
      {
        data: 'mail',
        label: 'メール'
      },
      {
        data: 'deadline',
        label: '締日'
      },
      {
        data: 'pay',
        label: '支払い方法'
      },
      {
        data: 'ccreditLimit',
        label: '与信限度額'
      },
      {
        data: 'free',
        label: '自由定義'
      },
      {
        data: 'possible',
        label: '受注可否'
      },
      {
        data: 'stopReason',
        label: '受注停止理由'
      },
      {
        data: 'category',
        label: 'お知らせ'

      },
      {
        data: 'status',
        label: '注文状況'
      }
    ],
    columns: [
      {
        data: 'mail',
        render() {
          return '<button class="btn btn-sm text-nowrap btn-white">照会</button>'
        }
      },
      {
        data: 'status',
        render() {
          return '<button class="btn btn-sm text-nowrap btn-white">照会</button>'
        }
      },
      {
        data: 'free',
        dtTdOverflowRender(data) {
          return data
        }
      },
      {
        data: 'category',

        render(data) {
          if (data === '納品書') {
            return '<span class="badge bg-warning text-white mr-1">' + data + '</span>'
          }

          if (data === '領収書') {
            return '<span class="badge bg-danger text-white mr-1">' + data + '</span>'
          }
        }

      }
    ]
  }

  $('input[name="csDaterange"]').daterangepicker({
    opens: 'left',
    ranges: {
      今日: [moment(), moment()],
      昨日: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      直近7日: [moment().subtract(6, 'days'), moment()],
      直近30日: [moment().subtract(29, 'days'), moment()],
      今月: [moment().startOf('month'), moment().endOf('month')],
      先月: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    buttonClasses: 'btn btn-sm mx-1',
    applyButtonClasses: 'btn-primary',
    cancelClass: 'btn-secondary'
  })

  $('#modal_search_branch_select').branchSelect({
    list: [
      {
        text: '枝番なし',
        value: '枝番なし'
      },
      {
        text: '001',
        value: '001'
      },
      {
        text: '002',
        value: '002'
      },
      {
        text: '003',
        value: '003'
      },
      {
        text: '004',
        value: '004'
      },
      {
        text: '005',
        value: '005'
      },
      {
        text: '006',
        value: '006'
      },
      {
        text: '012',
        value: '012'
      },
      {
        text: '014',
        value: '014'
      },
      {
        text: '057',
        value: '057'
      },
      {
        text: '100',
        value: '100'
      },
      {
        text: '255',
        value: '255'
      },
      {
        text: '256',
        value: '256'
      }
    ],
    selected: ['001'],
    // shortcutKeySelectAll: true
    selectboxSelectAll: true
  })

  const dtMethods = $('#myTableCS').DataTableMethods(settings)

  $('#btn-cs-search').on('click', () => {
    dtMethods.dtRun()
    $('#csSearchResult').show().addClass('show')
    $.fn.smooth({
      target: 'csSearchResult',
      scrollSelecotor: '#csSearchModal .modal-body'
    }).run()
  })

  // クリア
  $('#btn-cs-clear').on('click', () => {
    $('form#modalCsForm').each((index, form) => {
      $(form).find('input[type=text]').val('')

      $(form).find('select.branch_selected_list').empty()
      $(form).find('select').val('all')
    })
    $.fn.smooth({
      target: 'csSearchForm',
      scrollSelecotor: '#csSearchModal .modal-body'
    }).run()
    $('#csSearchResult').fadeOut()
  })

  $('#autoMatchConfirmModal').ModalMethods({
    $headerChild: $('<div>自動突合</div><button class="btn btn-pill btn-white modal-close" type="button" data-dismiss="modal" aria-label="Close" aria-invalid="false"><i class="c-icon cil-x font-weight-bold"></i></button>'),
    $bodyChild: $('<p>突合処理を実行しますが宜しいでしょうか?</p>'),
    $footerChild: $('<button class="btn btn-white" data-dismiss="modal" type="button" aria-invalid="false">キャンセル</button><button class="btn btn-primary" data-trigger="regist" type="button">自動突合</button>'),
    loading: {
      $headerChild: $('<div>自動突合</div>'),
      $bodyChild: $('<div>突合処理を実行しています。<br>画面操作を行わずそのままお待ちください。</div>')
    },
    error: {
      selector: '#modalErrorMessage'
    },
    form: {
      selector: '#formInput'
    },
    trigger: {
      regist: {
        ajax: {
          url: $.fn.getParam('rest_dummy_url') ? $.fn.getParam('rest_dummy_url') : 'js/json/modal_automatch_success_data.json'
        }
      }
    }

  }).modalRun()

  // 電話番号
})
